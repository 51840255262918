import React from "react";
import { Link } from "react-router-dom";

const SalesforceCards = ({ card }) => {
  return (
    <div className="flex xs:justify-center xs:items-center xs:text-center xs:flex-col lg:flex-row items-center bg-textColor rounded-lg shadow lg:max-w-3xl mx-auto my-4 lg:p-4">
      <img
        className="xs:w-auto md:w-auto rounded-t-lg md:rounded-none md:rounded-l-lg xs:h-56 md:h-64 shadow-lg"
        src={card.imageSrc}
        alt={card.alt}
      />
      <div className="flex flex-col justify-between leading-normal">
        <h2 className="text-lg font-bold text-primaryColor p-3">
          {card.title}
        </h2>
        <p className="text-primaryColor text-base p-3">{card.text}</p>
        <button className="px-4 py-2 mt-4 bg-blue-500 text-primaryColor rounded text-base mx-auto">
          <Link to="../contactez-nous">En savoir plus</Link>
        </button>
      </div>
    </div>
  );
};

export default SalesforceCards;
