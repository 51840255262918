import React from "react";
import { Helmet } from "react-helmet";
import Gallery from "../components/Equipe/gallery";

function Equipe() {
    return (
        <div className="">
            <Helmet>
                <title>
                    Notre Equipe - Rencontrez l'intégralité de l'équipe TechTalents
                </title>
                <meta
                    name="description"
                    content="Plongez dans l'histoire de Tech' Talents, notre mission, nos valeurs et notre engagement envers une technologie plus humaine et responsable. Découvrez comment nous transformons le paysage technologique avec passion."
                />
            </Helmet>
            <div className="xs:h-[15rem] lg:h-[30rem] bg-team bg-top bg-cover background-custom ">
                <div className="flex xs:h-[15rem] lg:h-[30rem] justify-center items-center bg-black/40">
                    <h1 className="uppercase xs:text-xl lg:text-2xl xl:text-3xl text-center text-white font-bold px-8 ">
                        La team tech talents
                    </h1>
                </div>
            </div>
            <Gallery />
        </div>
    );
}

export default Equipe;
