import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../App.css'

import CapencyLogo from '../../assets/images/capency-logo.webp'
import VerbaudetLogo from '../../assets/images/verbaudet-logo.webp'
import AddidasLogo from '../../assets/images/Logo-adidas-arena.png'
import FCNantesLogo from '../../assets/images/FcNantes.png'
import FCBordeauxLogo from '../../assets/images/LogoBordeaux.png'
import MbaLogo from '../../assets/images/ma-borne-auto-logo.webp'
import KbaneLogo from '../../assets/images/kbane.png'
import IanordLogo from '../../assets/images/ianord-logo.png'
import TechnodymLogo from '../../assets/images/technodym-logo.webp'
import AudaciaLogo from '../../assets/images/audacia-logo.webp'

function Responsive() {
    var settings = {
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        centerMode: true,
        centerPadding: '2rem',
        autoplay: true,
        speed: 500,
        autoplaySpeed: 1500,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 375,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    const logos = [
        { id: 1, url: CapencyLogo, alt: 'Logo de Capency, partenaire expert data' },
        { id: 2, url: AudaciaLogo, alt: 'Logo d\'Audacia, entreprise d\'investissements en Private Equity' },
        { id: 3, url: IanordLogo, alt: 'Logo de Ianord entreprise pour laquelle TechTalents fait de la régie' },
        { id: 4, url: AddidasLogo, alt: 'Logo de Accor Arena, Salle événements sur Paris' },
        { id: 5, url: FCNantesLogo, alt: 'Logo du FC Nantes, Club de Foot Ligue 1' },
        { id: 6, url: FCBordeauxLogo, alt: 'Logo des Girondins de Bordeaux, Rugby' },
        { id: 7, url: KbaneLogo, alt: 'Logo de Kbane, partenaire écologique accès sur la vente de mobiliers en bois' },
        { id: 8, url: MbaLogo, alt: 'Logo de MaBorneAuto, partenaire ecologique accès sur la vente de panneaux photovoltaïques' },
        { id: 9, url: TechnodymLogo, alt: 'Logo de Technodym, partenaire chez qui TechTalents fais de la régie informatique' },
        { id: 10, url: VerbaudetLogo, alt: 'Logo de Verbaudet, partenaire qui vend des vêtements éco responsables' },
    ];

    return (
        <div className="flex flex-col text-center justify-center bg-secondaryColor min-h-[25rem] overflow-hidden">
            <h2 className="text-white text-xl mb-20 uppercase font-black">Ils nous font confiance</h2>
            <Slider {...settings}>
                {logos.map(logos => (
                    <div key={logos.id}>
                        <img src={logos.url} alt={logos.alt} className="xs:h-24 w-auto md:h-24 lg:h-32 xl:h-32 object-contain mx-auto" />
                    </div>
                ))}
            </Slider>

        </div>
    );
}

export default Responsive;
