import React from "react";
import Form from "../components/Contact/form";
import contact from "./../assets/images/contact.svg"

function ContactTest() {
  return (
    <div className="h-full">
      <h2 className="text-2xl p-6 uppercase font-bold text-textColor text-center">
        Contactez-nous
      </h2>
      <div className="flex flex-col md:flex-row justify-evenly ">
        <div className="flex items-center flex-col text-center">
          <div className="">
            <img
              className="max-w-[40rem] w-full h-auto object-coverx"
              src={contact}
            />
          </div>
        </div>
        <div className="justify-center items-center">
          <Form />
        </div>
      </div>
    </div>
  );
}

export default ContactTest;
