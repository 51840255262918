import React from "react";
import { Link } from "react-router-dom";

const RegieCards = ({ card }) => {
  return (
    <div className="flex flex-col lg:flex-row items-center bg-textColor rounded-lg shadow-lg max-w-2xl mx-auto xs:my-2 lg:my-4 ">
      <img
        className="w-full lg:w-56 rounded-t-lg lg:rounded-none lg:rounded-l-lg h-full shadow-lg mb-4 lg:mb-0 object-cover xs:max-h-56 lg:max-h-full"
        src={card.imageSrc}
        alt={card.alt}
      />
      <div className="flex flex-col justify-between leading-normal p-4 lg:p-8">
        <h2 className="text-sm sm:text-base md:text-md lg:text-md font-bold text-primaryColor text-center">
          {card.title}
        </h2>
        <p className="text-xs sm:text-sm md:text-base lg:text-base text-primaryColor mt-2">
          {card.text}
        </p>
        <button className="px-4 py-2 mt-4 bg-blue-500 text-white rounded text-xs sm:text-sm md:text-base lg:text-base mx-auto">
          <Link to="../contactez-nous">En savoir plus</Link>
        </button>
      </div>
    </div>
  );
};

export default RegieCards;
