import React from "react";
import { Helmet } from "react-helmet";
import JobCard from "./../components/Rejoindre/JobCards";

const jobData = [
  {
    location: "Lille",
    imageSrc:
      "https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    altText:
      "Image mettant en avant la recherche d'un alternant en gestion de projets chez tech'talents",
    title: "Chef de Projet (Alternant)",
    description:
      "Nous sommes à la recherche d'un alternant en chefferie de projet pour septembre 2024",
    link: "/chef-de-projets-alternance",
  },
  {
    location: "Lille",
    imageSrc:
      "https://images.unsplash.com/photo-1605379399642-870262d3d051?q=80&w=2706&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    altText: "Image mettant en avant la recherche d'un développeur web",
    title: "Développeur(se) Full Stack NodeJS",
    description:
      "Nous recherchons un développeur fullstack passionné pour rejoindre notre équipe dynamique",
    link: "/developpeur-full-stack-nodejs",
  },
];

function Rejoindre() {
  return (
    <div>
      <Helmet>
        <title>
          Rejoignez Nous - Découvrez les différentes offres d'emplois proposées
          par TechTalents
        </title>
        <meta
          name="description"
          content="Plongez dans l'histoire de Tech' Talents, notre mission, nos valeurs et notre engagement envers une technologie plus humaine et responsable. Découvrez comment nous transformons le paysage technologique avec passion."
        />
      </Helmet>
      <div className="xs:h-[15rem] lg:h-[30rem] bg-joinus bg-center bg-cover object-center">
        <div className="flex xs:h-[15rem] lg:h-[30rem] justify-center items-center bg-black/20">
          <h1 className="uppercase xs:text-lg lg:text-2xl xl:text-3xl text-center text-white font-bold px-8">
            Nous rejoindre
          </h1>
        </div>
      </div>
      <div className="flex flex-wrap">
        {jobData.map((job, index) => (
          <JobCard
            key={index}
            location={job.location}
            imageSrc={job.imageSrc}
            altText={job.altText}
            title={job.title}
            description={job.description}
            link={job.link}
          />
        ))}
      </div>
    </div>
  );
}

export default Rejoindre;
