import Ours from "../../assets/images/ours.webp";
import Chat from "../../assets/images/chat.webp";
import Einstein from "../../assets/images/einstein.webp";
import Elephant from "../../assets/images/ele.webp";

const SalesforceData = [
  {
    id: 1,
    imageSrc: Ours,
    alt: "Test de alt",
    title: "Optimisation des performances et révision des processus",
    text: "Boostez votre efficacité et peaufinez vos méthodes de travail grâce à notre approche validée chez plusieurs clients.",
  },
  {
    id: 2,
    imageSrc: Chat,
    alt: "Test de alt",
    title: "Intégration de Salesforce avec vos systèmes existants",
    text: "Assurez une transition fluide et une collaboration efficace entre Salesforce et vos outils actuels grâce à notre expertise.",
  },
  {
    id: 3,
    imageSrc: Einstein,
    alt: "Test de alt",
    title: "Formation et support utilisateur",
    text: "Bénéficiez d'une formation personnalisée et d'un support continu pour maximiser l'adoption de Salesforce par vos équipes.",
  },
  {
    id: 4,
    imageSrc: Elephant,
    alt: "Test de alt",
    title: "Développement de solutions personnalisées",
    text: "Créez des solutions sur mesure qui répondent exactement à vos besoins et améliorez la performance globale de votre entreprise avec Salesforce.",
  },
];

export default SalesforceData;
