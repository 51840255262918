import React, { useState } from "react";

const RegieTab = () => {
  const [activeTab, setActiveTab] = useState("backend");

  const tabs = [
    {
      id: "backend",
      label: "API et services back-end",
      content:
        "Nos équipes conçoivent et développent des API et des services back-end performants avec Node.js. Ces solutions permettent une communication fluide entre les différentes parties de votre système, offrant ainsi une expérience utilisateur optimale.",
    },
    {
      id: "Migration",
      label: "Migration vers Node.js",
      content:
        "Si vous envisagez de migrer votre application vers Node.js pour bénéficier de ses avantages en termes de vitesse, de scalabilité et de performance, nous pouvons vous aider à planifier et à exécuter cette transition en toute transparence.",
    },
    {
      id: "Maintenance",
      label: "Maintenance et support continu",
      content:
        "En plus du développement initial, nous proposons des services de maintenance et de support continu pour garantir que votre application Node.js reste opérationnelle et à jour avec les dernières normes et pratiques de l'industrie.",
    },
  ];

  const buttonClass =
    "bg-textColor inline-flex flex-grow items-center justify-center py-2 px-4 text-primaryColor font-bold text-pretty xs:text-sm lg:text-base";
  return (
    <div className="p-4 shadow-xl xs:w-full lg:w-2/3 bg-textColor rounded">
      <ul className="flex pb-2 bg-textColor">
        {tabs.map((tab) => (
          <li
            key={tab.id}
            className="flex-1 text-center text-base bg-textColor"
          >
            <button
              className={`${buttonClass} ${
                activeTab === tab.id ? "active-tab" : ""
              }`}
              onClick={() => setActiveTab(tab.id)}
              style={{ flex: "1 1 0%" }} // Utiliser flex pour assurer que tous les boutons ont la même largeur
            >
              {tab.label}
            </button>
          </li>
        ))}
      </ul>
      <div className="tab-content pt-4 w-full">
        <div className="p-4 text-primaryColor text-base">
          {tabs.find((tab) => tab.id === activeTab)?.content}
        </div>
      </div>
    </div>
  );
};

export default RegieTab;
