import React, { useState, useCallback } from "react";
import { LinkedIn } from "@mui/icons-material";
import galleryData from "../../galleryData.js";
import { Link } from "react-router-dom";

function Gallery() {
  const [hoveredItem, setHoveredItem] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("all");

  const handleMouseEnter = useCallback((index) => {
    setHoveredItem(index);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHoveredItem(null);
  }, []);

  const setFilter = useCallback((filter) => {
    setSelectedFilter(filter);
  }, []);

  const filteredGalleryData = galleryData.filter((item) => {
    return selectedFilter === "all" || item.categories.includes(selectedFilter);
  });

  return (
    <div className="mx-10 mt-10 h-auto">
      <div className="flex sm:p-8 xs:justify-center md:py-8 flex-wrap mx-auto mb-8">
        {["all", "gestion projet", "developpement", "direction"].map(
          (filter) => (
            <button
              key={filter}
              type="button"
              className={`capitalize text-textColor border-b-4 border-textColor hover:border-textColor hover:border-b-4 focus:outline-none text-base font-medium px-5 py-2.5 text-center me-3 mb-3 ${
                selectedFilter === filter ? "active-border" : ""
              }`}
              onClick={() => setFilter(filter)}
            >
              {filter === "all" ? "Tout" : filter}
            </button>
          )
        )}
      </div>
      <div className="grid xs:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        {filteredGalleryData.map((item) => (
          <div
            key={item.id}
            className="relative max-w-[20rem] max-h-[20rem] card"
            onMouseEnter={() => handleMouseEnter(item.id)}
            onMouseLeave={handleMouseLeave}
          >
            <img
              className="h-full w-full rounded-lg object-cover"
              src={item.imageUrl}
              alt={`Product ${item.alt}`}
            />
            <div className="hover-overlay">
              <div className="text-white text-center">
                <h2 className="xs:text-md lg:text-xl font-bold">{item.name}</h2>
                <h2 className="xs:md lg:text-md p-4">{item.title}</h2>
                <p className="mx-8 xs:hidden lg:text-lg">{item.description}</p>
                <Link
                  to={item.linkedinUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LinkedIn
                    style={{ fontSize: "2rem" }}
                    className="text-blue-500 hover:text-blue-700 bg-white m-2 rounded"
                  />
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Gallery;
