import React from "react";
import logo from "../assets/images/logo_blanc.svg";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Link } from "react-router-dom";

function FooterMenu() {
  return (
    <div className="border-t z-40">
      <div className="grid xs:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 h-auto bg-navColor z-50 items-center">
        <div className="flex flex-col p-6 items-center justify-center z-40">
          <img
            className="xs:h-8 md:h-10 lg:h-14"
            src={logo}
            alt="Logo de Tech'Talents entreprise de services numériques basée sur Lille, dans les hauts de france"
          />
        </div>

        <div className="p-10 items-center justify-center text-center z-40">
          <h2 className="text-white text-md font-extrabold mb-2 uppercase">
            Notre agence
          </h2>
          <p className="text-white text-md">
            {" "}
            3 rue Princesse <br />
            59800 Lille
          </p>
          <h2 className="text-white text-md font-extrabold mb-2 uppercase mt-10">
            Suivez-Nous
          </h2>
          <Link
            to="https://www.linkedin.com/company/tech-talentsfr/mycompany/"
            className="text-white text-left"
            target="blank"
          >
            <LinkedInIcon style={{ fontSize: "3rem" }} />
          </Link>
        </div>
        <div className="flex flex-col items-center justify-center text-center z-40">
          <h2 className="text-white text-md font-extrabold uppercase mb-2">
            Où nous trouver
          </h2>
          <iframe
            title="Emplacement de Tech'Talents, Esn RSE"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2529.9733182048158!2d3.057090477561489!3d50.6461866732498!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c32a7bbdd3c0bb%3A0x88ad2d226866c812!2s3%20Rue%20Princesse%2C%2059800%20Lille!5e0!3m2!1sfr!2sfr!4v1707239901186!5m2!1sfr!2sfr"
            style={{
              width: "auto",
              height: "100%",
              border: "0",
            }}
          ></iframe>
        </div>
      </div>

      <div className="flex xs:flex-col-reverse md:flex-col-reverse md:items-center md:justify-center lg:flex-row items-center h-auto py-5 bg-secondaryColor gap-10 justify-center z-40">
        <Link to="mentions-legales" className="text-white ml-5 z-40">
          Mentions légales
        </Link>
        <Link to="protection-donnees" className="text-white ml-5 z-40">
          Politique de protection des données
        </Link>
      </div>
      <div className="flex justify-center bg-secondaryColor p-4 ">
        <a href="/" className="text-white ml-5 z-40">
          Copyright © 2024 Tech'Talents
        </a>
      </div>
    </div>
  );
}

export default FooterMenu;
