import React from 'react';
import Masonry from 'react-masonry-css';

import Cuisine from "../../assets/images/cuisine.webp";
import Detente from "../../assets/images/detente.webp";
import Openspace from "../../assets/images/openspace.webp";
import Reunion from "../../assets/images/reunion.webp";
import Reunion2 from "../../assets/images/reunion2.webp";
import Reunion3 from "../../assets/images/reunion3.webp";

const images = [
    { src: Cuisine, class: 'wide' },
    { src: Detente, class: 'tall' },
    { src: Openspace, class: 'tall' },
    { src: Reunion, class: 'wide' },
    { src: Reunion2, class: 'tall' },
    { src: Reunion3, class: 'normal' },
];

const breakpointColumnsObj = {
    default: 3,
    1100: 2,
    700: 1
};

function MasonryGrid() {
    return (
        <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
        >
            {images.map((image, index) => (
                <div key={index} className={`grid-item ${image.class}`}>
                    <img src={image.src} alt={`Image ${index}`} />
                </div>
            ))}
        </Masonry>
    );
}

export default MasonryGrid;
