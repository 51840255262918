import React from "react";
import Slider from "react-slick";
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from "react-icons/fa";
import Partenaire from "../../assets/images/Timeline/partenaire.jpg";
import Recrutement from "../../assets/images/Timeline/recrutement.webp";
import Teambuildings from "../../assets/images/Timeline/Teambuilding.png";
import Inauguration from "../../assets/images/Timeline/inauguration.jpg";
import Salesforcetour from "../../assets/images/Timeline/Salesforcetour.png";
import Mission from "../../assets/images/Timeline/mission.png";
import Alternants from "../../assets/images/Timeline/alternant.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const NextArrow = ({ onClick }) => {
  return (
    <div
      className="absolute top-1/2 right-4 -translate-y-1/2 cursor-pointer z-10"
      onClick={onClick}
    >
      <FaArrowAltCircleRight size={30} className="text-white" />
    </div>
  );
};

const PrevArrow = ({ onClick }) => {
  return (
    <div
      className="absolute top-1/2 left-4 -translate-y-1/2 cursor-pointer z-10"
      onClick={onClick}
    >
      <FaArrowAltCircleLeft size={30} className="text-white" />
    </div>
  );
};

const Timeline = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="bg-transparent ">
      <Slider {...settings}>
        {cards.map((card) => (
          <Card card={card} key={card.id} />
        ))}
      </Slider>
    </div>
  );
};

const Card = ({ card }) => {
  return (
    <div className="relative h-[80vh] w-full overflow-hidden bg-center">
      <div
        style={{
          backgroundImage: `url(${card.url})`,
          backgroundSize: "cover",
          backgroundPosition: card.position,
          height: "100%",
          width: "100%",
        }}
        className="absolute inset-0 z-0 transition-transform duration-300"
        role="img"
        aria-label={card.alt}
      ></div>
      <div className="absolute inset-0 z-10 grid place-content-center bg-black/40">
        <h2 className="px-8 py-2 text-3xl font-black uppercase text-white xs:text-md lg:text-xl">
          {card.title}
        </h2>
        <p className="px-8 py-4 text-xl font-black uppercase text-white xs:text-md lg:text-xl">
          {card.text}
        </p>
      </div>
    </div>
  );
};

export default Timeline;

const cards = [
  {
    url: "https://images.pexels.com/photos/356056/pexels-photo-356056.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=18",
    title: "Création de Tech' Talents",
    text: "2021",
    id: 1,
    position: "center",
    alt: "Lancement de Tech' Talents, ESN spécialisée en Salesforce pour le sport et l'événementiel",
  },
  {
    url: Partenaire,
    title: "Tech’ Talents devient partenaire salesforce",
    text: "2021",
    id: 2,
    position: "center",
    alt: "Partenariat de Tech Talents avec Salesforce, intégrateur système dans le sport",
  },
  {
    url: Recrutement,
    title: "Recrutement & mise en place des équipes",
    text: "2022",
    id: 3,
    position: "center",
    alt: "Recrutement chez Tech Talents, équipes IT spécialisées en Salesforce et API",
  },
  {
    url: Teambuildings,
    title: "Teambuildings",
    text: "2022",
    id: 4,
    position: "center",
    alt: "Teambuilding chez Tech Talents, renforcement déquipe pour projets IT en sport",
  },
  {
    url: Inauguration,
    title: "Inauguration des bureaux",
    text: "2023",
    id: 5,
    position: "center",
    alt: "Inauguration des nouveaux bureaux de Tech Talents, espace de travail pour innovation IT",
  },
  {
    url: Alternants,
    title: "Premiers alternants",
    text: "2023",
    id: 6,
    position: "top",
    alt: "Premiers alternants chez Tech Talents, formation Salesforce, développement Node.js et Communication ",
  },
  {
    url: Mission,
    title: "Tech’ Talents devient entreprise à mission",
    text: "2023",
    id: 7,
    position: "center",
    alt: "Tech Talents devient une entreprise à mission RSE, engagement en technologies responsables",
  },
  {
    url: Salesforcetour,
    title: "Tech’ Talents participe au SF World Tour",
    text: "2024",
    id: 8,
    position: "center",
    alt: "Participation de Tech Talents au Salesforce World Tour, présentation de solutions cloud et API",
  },
];
