import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet'
function PrivacyPolicy() {
    return (
        <div className="container mx-auto p-4 flex justify-center flex-col xs:mx-8 lg:mx-20">
            {/* Métadonnées de la page */}
            <Helmet>
                <title>Politique de Protection des Données</title>
                <meta name="description" content="Politique de protection des données de notre site." />
            </Helmet>

            {/* Titre de la page */}
            <h1 className="text-2xl font-bold my-4 text-textColor">Politique de Protection des Données</h1>

            {/* Introduction */}
            <section className='xs:my-8 lg:my-4'>
                <p className='text-textColor' >Nous prenons votre vie privée au sérieux. Cette politique de protection des données explique quelles informations nous collectons, comment nous les utilisons, comment elles sont sécurisées et dans quelles circonstances nous les partageons.</p>
            </section>

            {/* Collecte des données */}
            <section className='xs:my-8 lg:my-4'>
                <h2 className="xs:text-md lg:text-xl font-semibold mt-6 text-textColor">Collecte des Données</h2>
                <p className='text-textColor'>Nous collectons des informations vous concernant lorsque vous utilisez notre site, notamment :</p>
                <ul className="list-disc ml-5">
                    <li className='text-textColor'>Informations de contact telles que nom, adresse email, et numéro de téléphone.</li>
                    <li className='text-textColor'>Données de navigation, comme les pages visitées et les interactions avec le site.</li>
                </ul>
            </section>

            {/* Utilisation des données */}
            <section className='xs:my-8 lg:my-4'>
                <h2 className="xs:text-md lg:text-xl font-semibold mt-6 text-textColor">Utilisation des Données</h2>
                <p className='text-textColor'>Nous utilisons vos données pour :</p>
                <ul className="list-disc ml-5 text-textColor">
                    <li>Améliorer l'expérience utilisateur sur notre site.</li>
                    <li>Communiquer avec vous au sujet de votre compte ou de nos services.</li>
                </ul>
            </section>

            {/* Partage des données */}
            <section className='xs:my-8 lg:my-4'>
                <h2 className="xs:text-md lg:text-xl font-semibold mt-6 text-textColor">Partage des Données</h2>
                <p className='text-textColor'>Nous ne partageons vos données avec des tiers que dans les cas suivants :</p>
                <ul className="list-disc ml-5 text-textColor">
                    <li>Avec votre consentement explicite.</li>
                    <li>Pour respecter la loi, une ordonnance judiciaire, ou une procédure légale.</li>
                </ul>
            </section>

            {/* Sécurité des données */}
            <section className='xs:my-8 lg:my-4'>
                <h2 className="xs:text-md lg:text-xl font-semibold mt-6 text-textColor">Sécurité des Données</h2>
                <p className='text-textColor'>Nous mettons en place des mesures de sécurité pour protéger vos données contre l'accès non autorisé, l'altération, la divulgation ou la destruction.</p>
            </section>

            {/* Droits des utilisateurs */}
            <section className='xs:my-8 lg:my-4'>
                <h2 className="xs:text-md lg:text-xl font-semibold mt-6 text-textColor">Vos Droits</h2>
                <p className='text-textColor'>Vous avez le droit de consulter, modifier ou supprimer vos informations personnelles. Pour cela, veuillez nous contacter à <Link className='text-textColor underline font-extrabold' to="mailto:anais@tech-talents.fr">anais@tech-talents.fr</Link>.</p>
            </section>


            {/* Modifications de la politique */}
            <section className='xs:my-8 lg:my-4'>
                <h2 className="xs:text-md lg:text-xl font-semibold mt-6 text-textColor">Modifications de la Politique</h2>
                <p className='text-textColor'>Nous pouvons mettre à jour notre politique de protection des données de temps à autre. Nous vous notifierons de toute modification en mettant à jour la politique sur notre site.</p>
            </section>

            {/* Contact */}
            <section className='xs:my-8 lg:my-4'>
                <h2 className="xs:text-md lg:text-xl font-semibold mt-6 text-textColor">Contact</h2>
                <p className='text-textColor'>Pour toute question ou préoccupation relative à notre politique de protection des données, veuillez nous contacter à <Link className='text-textColor underline font-extrabold' to="mailto:anais@tech-talents.fr">anais@tech-talents.fr</Link></p>
            </section>
        </div>
    );
}

export default PrivacyPolicy;