import React from "react";
import { Helmet } from "react-helmet";
import RegieData from "../components/Regie/RegieData";
import RegieCards from "../components/Regie/RegieCards";
import RegieTab from "../components/Regie/RegieTabs";

function Regie() {
  return (
    <div>
      <Helmet>
        <title>Contactez Tech Talents - Nous sommes là pour vous aider</title>
        <meta
          name="description"
          content="Vous avez des questions ou besoin d'une expertise ? Contactez Tech Talents dès aujourd'hui pour discuter de vos projets et découvrir comment nous pouvons vous aider."
        />
      </Helmet>
      <div className="flex xs:h-[15rem] lg:h-[30rem] items-center justify-center bg-regie bg-bottom bg-cover">
        <div className="w-full flex flex-col items-center justify-center gap-5 bg-black/10 h-[30rem]">
          <h1 className="xs:text-lg lg:text-2xl text-center p-2 text-white font-extrabold uppercase">
            La Régie
          </h1>
        </div>
      </div>
      {/* Cards */}
      <div className="grid xs:grid-cols-1 lg:grid-cols-2 gap-12 mx-4">
        {RegieData.map((card) => (
          <RegieCards key={card.id} card={card} />
        ))}
      </div>
      <div className="flex justify-center mx-auto my-10">
        <RegieTab />
      </div>
    </div>
  );
}

export default Regie;
