import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo_blanc.svg";
import ToggleActive from "./ToggleActive";
import ChevronDownIcon from "@mui/icons-material/ExpandMore";
import "../../App.css";

function NavbarMenu() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [techTalentsDropdownOpen, setTechTalentsDropdownOpen] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleTechTalentsDropdown = () => {
    if (!isLargeScreen) {
      setTechTalentsDropdownOpen(!techTalentsDropdownOpen);
    }
  };

  useEffect(() => {
    const checkScreenSize = () => {
      setIsLargeScreen(window.innerWidth >= 1024);
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  return (
    <nav className="sticky top-0 z-50 bg-primaryColor border-b font-bold uppercase">
      <div className="flex flex-wrap items-center xs:justify-between lg:justify-center xl:justify-between mx-auto p-2 relative z-20">
        <Link
          to="/"
          className="flex items-center space-x-2 rtl:space-x-reverse px-2 sm:px-4 md:px-6"
        >
          <img
            src={Logo}
            className="h-6 xs:h-5 md:h-6 lg:h-8  inverse-colors"
            alt="Logo de Tech Talents esn experte salesforce eco résponsable"
          />
        </Link>
        <button
          onClick={toggleMenu}
          aria-expanded={menuOpen}
          className="w-14 h-14 text-textColor inline-flex items-center justify-center lg:hidden xl:hidden 2xl:hidden relative"
          aria-label="Menu"
        >
          <div
            aria-hidden="true"
            className={`hamburger-menu ${menuOpen ? "open" : ""}`}
          >
            <span className="hamburger-line"></span>
            <span className="hamburger-line"></span>
            <span className="hamburger-line"></span>
          </div>
        </button>
        <div
          className={`w-full lg:flex lg:items-center lg:w-auto ${
            menuOpen ? "block" : "hidden"
          }`}
          id="navbar-default"
        >
          <ul className="flex flex-col lg:flex-row lg:space-x-8 p-4">
            <li
              className="relative"
              onMouseEnter={() =>
                isLargeScreen && setTechTalentsDropdownOpen(true)
              }
              onMouseLeave={() =>
                isLargeScreen && setTechTalentsDropdownOpen(false)
              }
            >
              <div
                className="py-2 px-3 text-textColor text-base cursor-pointer font-extrabold"
                onClick={toggleTechTalentsDropdown}
              >
                Tech' Talents
                <ChevronDownIcon
                  className={`ml-1 ${
                    techTalentsDropdownOpen ? "rotate-180" : "rotate-0"
                  }`}
                />
              </div>
              {techTalentsDropdownOpen && (
                <ul className="absolute bg-primaryColor shadow-lg py-2 z-20 w-auto">
                  <li>
                    <Link
                      to="/notre-histoire"
                      className="block px-4 py-2 text-textColor font-extrabold nav-link"
                    >
                      Notre Histoire
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/notre-equipe"
                      className="block px-4 py-2 text-textColor font-extrabold nav-link"
                    >
                      Notre équipe
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/nos-valeurs"
                      className="block px-4 py-2 text-textColor font-extrabold nav-link"
                    >
                      Nos Valeurs
                    </Link>
                  </li>
                </ul>
              )}
            </li>
            <li>
              <Link
                to="/services-informatiques"
                className="block py-2 px-3 text-textColor text-base font-extrabold nav-link"
              >
                Nos Services
              </Link>
            </li>
            <li>
              <Link
                to="/nous-rejoindre"
                className="block py-2 px-3 text-textColor text-base font-extrabold nav-link"
              >
                Nous Rejoindre
              </Link>
            </li>
            {/* <li><Link to="/nos-actus-tech" className="block py-2 px-3 text-textColor text-base font-extrabold nav-link">Actus Tech</Link></li> */}
            <li>
              <Link
                to="/contactez-nous"
                className="block py-2 px-3 text-textColor text-base font-extrabold nav-link"
              >
                Contact
              </Link>
            </li>
            <li>
              <ToggleActive />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default NavbarMenu;
