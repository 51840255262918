import React, { useState } from "react";

const ExpertiseTab = () => {
  const [activeTab, setActiveTab] = useState("WebDevelopment");

  const tabs = [
    {
      id: "WebDevelopment",
      label: "Création de sites web",
      content:
        "Développez des sites web dynamiques et réactifs. Nos équipes spécialisées en front-end et back-end utilisent les technologies les plus récentes pour créer des expériences utilisateur captivantes et performantes.",
    },
    {
      id: "APISolutions",
      label: "Développement de solutions API",
      content:
        "Concevez des API robustes et sécurisées pour faciliter l'intégration et l'interopérabilité de vos applications. Nos solutions API vous permettent de connecter efficacement vos services et données avec divers systèmes et plateformes.",
    },
    {
      id: "DataManagement",
      label: "Gestion des données",
      content:
        "Optimisez la collecte, le stockage et l'analyse de vos données. Nous vous aidons à mettre en place des structures et des processus pour gérer efficacement vos données, garantissant l'accessibilité, la sécurité et la conformité.",
    },
    {
      id: "SystemAgility",
      label: "Agilité du système d'information",
      content:
        "Améliorez la flexibilité et la réactivité de votre système d'information. Notre approche vise à rendre votre SI plus adaptable aux changements, en supportant l'évolution rapide des besoins métier grâce à des technologies évolutives.",
    },
  ];

  const buttonClass =
    "bg-textColor inline-flex flex-grow items-center justify-center py-2 px-4 text-primaryColor font-bold text-pretty xs:text-sm lg:text-base";
  return (
    <div className="p-4 shadow-xl xs:w-full lg:w-2/3 bg-textColor rounded">
      <ul className="flex pb-2 bg-textColor">
        {tabs.map((tab) => (
          <li
            key={tab.id}
            className="flex-1 text-center text-base bg-textColor"
          >
            <button
              className={`${buttonClass} ${
                activeTab === tab.id ? "active-tab" : ""
              }`}
              onClick={() => setActiveTab(tab.id)}
              style={{ flex: "1 1 0%" }} // Utiliser flex pour assurer que tous les boutons ont la même largeur
            >
              {tab.label}
            </button>
          </li>
        ))}
      </ul>
      <div className="tab-content pt-4 w-full">
        <div className="p-4 text-primaryColor text-base">
          {tabs.find((tab) => tab.id === activeTab)?.content}
        </div>
      </div>
    </div>
  );
};

export default ExpertiseTab;
