import React, { Suspense, lazy, useState } from "react";
import "typeface-nunito-sans";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import "./App.css";
// Components
import NavbarMenu from "./components/navbar/NavbarMenu.js";
import FooterMenu from "./components/Footer.js";
import ScrollToTop from './components/ScrollToTop';

// Pages
import Home from "./pages/Home.js";
import NotreHistoire from "./pages/NotreHistoire.js";
import Equipe from "./pages/Equipe.js";
import NosValeurs from "./pages/NosValeurs.js";
import NotreExpertise from "./pages/NotreExpertise.js";
import Contact from "./pages/Contact.js";
import Rejoindre from "./pages/Rejoindre.js";
import Salesforce from "./pages/Salesforce.js";
import ExpertiseIt from "./pages/ExpertiseIt.js";
import Regie from "./pages/Regie.js";
import Dropbox from "./pages/Dropbox.js";
import ChefProjets from "./pages/ChefProjets";
import FullStackDeveloper from "./pages/FullStackDeveloper";
import MentionsLegales from "./pages/MentionsLegales.js";
import PrivacyPolicy from "./pages/PolitiqueDonnees.js";

function App() {
  return (
    <div className="App bg-primaryColor">
      <Router>
        <ScrollToTop />
        <NavbarMenu />
        <Routes>
          <Route index element={<Home />} />
          <Route path="notre-histoire" element={<NotreHistoire />} />
          <Route path="notre-equipe" element={<Equipe />} />
          <Route path="nos-valeurs" element={<NosValeurs />} />
          <Route path="services-informatiques" element={<NotreExpertise />} />
          <Route path="expertise-salesforce" element={<Salesforce />} />
          <Route path="expertise-it" element={<ExpertiseIt />} />
          <Route path="regie" element={<Regie />} />
          <Route path="dropbox" element={<Dropbox />} />
          <Route path="nous-rejoindre" element={<Rejoindre />} />
          <Route path="contactez-nous" element={<Contact />} />
          <Route path="chef-de-projets-alternance" element={<ChefProjets />} />
          <Route path="developpeur-full-stack-nodejs" element={<FullStackDeveloper />} />
          <Route path="mentions-legales" element={<MentionsLegales />} />
          <Route path="protection-donnees" element={<PrivacyPolicy />} />
        </Routes>
        <FooterMenu />
      </Router>
    </div>
  );
}

export default App;
