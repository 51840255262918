import React from "react";
import { Helmet } from "react-helmet";

function MentionsLegales() {
  return (
    <div className="xs:mx-8 lg:mx-20 p-4 flex flex-col">
      <Helmet>
        <title>Mentions Légales - Tech’ Talents</title>
        <meta
          name="description"
          content="Mentions légales du site Tech’ Talents."
        />
      </Helmet>

      <h1 className="xs:text-md text-xl font-bold my-4 text-textColor">
        Mentions Légales
      </h1>

      {/* Éditeur du Site */}
      <section className="text-textColor xs:my-8">
        <h2 className="xs:text-md lg:text-xl font-semibold mt-6 text-textColor">
          Éditeur du Site
        </h2>
        <p>
          Le site www.tech-talents.fr est édité par la société Tech’ Talents,
          société par actions simplifiée immatriculée au Registre du Commerce et
          des Sociétés de LILLE METROPOLE sous le numéro SIRET 905 398 939
          00017.
        </p>
        <p>Adresse : 3 rue Princesse, 59800 Lille, France</p>
        <p>Email : contact@tech-talents.fr</p>
        <p>Téléphone : 06.26.36.76.89</p>
        <p>
          Directeur de la publication : Anaïs HELIE-BEUGIN – Présidente de la
          SAS
        </p>
      </section>

      {/* Hébergement */}
      <section className="text-textColor xs:mb-8">
        <h2 className="xs:text-md lg:text-xl font-semibold mt-6">
          Hébergement
        </h2>
        <p>
          Le site est hébergé par OVH, situé au 2, rue Kellermann, 59100
          Roubaix, France.
        </p>
      </section>

      {/* Propriété Intellectuelle */}
      <section className="text-textColor xs:mb-8">
        <h2 className="xs:text-md lg:text-xl font-semibold mt-6">
          Propriété Intellectuelle
        </h2>
        <p>
          La dénomination Tech’ Talents, les logos Tech’ Talents et les slogans
          sont, sauf indication particulière, des marques déposées par Tech’
          Talents. Les dessins, photographies, images, textes, graphiques et
          autres documentations sont protégés par les lois en vigueur sur la
          propriété intellectuelle et sont soit propriété de Tech’ Talents, soit
          de tiers ayant autorisé Tech’ Talents à les utiliser.
        </p>
        <p>
          Toute reproduction, représentation, modification, publication,
          adaptation de tout ou partie des éléments du site, quel que soit le
          moyen ou le procédé utilisé, est interdite, sauf autorisation écrite
          préalable de Tech’ Talents.
        </p>
        <p>Crédit photos : Tech’ Talents, istockphoto.</p>
      </section>

      {/* Responsabilité */}
      <section className="text-textColor xs:mb-8">
        <h2 className="xs:text-md lg:text-xl font-semibold mt-6">
          Responsabilité
        </h2>
        <p>
          Tech’ Talents s’efforce de fournir sur le site www.tech-talents.fr des
          informations aussi précises que possible. Toutefois, elle ne pourra
          être tenue responsable des omissions, des inexactitudes et des
          carences dans la mise à jour, qu’elles soient de son fait ou du fait
          des tiers partenaires qui lui fournissent ces informations.
        </p>
        <p>
          Toutes les informations indiquées sur le site www.tech-talents.fr sont
          données à titre indicatif, et sont susceptibles de changer ou
          d’évoluer sans préavis.
        </p>
      </section>

      {/* Données Personnelles */}
      <section className="text-textColor xs:mb-8">
        <h2 className="xs:text-md lg:text-xl font-semibold mt-6">
          Données Personnelles
        </h2>
        <p>
          Conformément au règlement général sur la protection des données (RGPD)
          et à la loi Informatique et Libertés, Tech’ Talents s’engage à ce que
          la collecte et le traitement des données personnelles, effectués à
          partir de ce site, soient conformes à ces réglementations.
        </p>
        <p>
          Vous disposez d’un droit d’accès, de rectification, d’effacement et
          d’opposition au traitement de vos données personnelles. Vous pouvez
          exercer ces droits en nous contactant à l’adresse suivante :
          contact@tech-talents.fr
        </p>
      </section>

      {/* Traitement des Réclamations */}
      <section className="text-textColor xs:mb-8">
        <h2 className="xs:text-md lg:text-xl font-semibold mt-6">
          Traitement des Réclamations
        </h2>
        <p>
          En cas de réclamation, les clients peuvent s’adresser directement à
          Madame Anaïs HELIE-BEUGIN, Présidente de Tech’ Talents, par voie
          électronique à contact@tech-talents.fr ou par téléphone au
          06.26.36.76.89.
        </p>
        <p>
          Une réponse à la réclamation sera apportée dans un délai maximal de
          deux mois à compter de la date de réception de la réclamation, sauf
          survenance de circonstances particulières dûment justifiées.
        </p>
      </section>
    </div>
  );
}

export default MentionsLegales;
