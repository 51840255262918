import Einstein from "../../assets/images/einstein.webp";
import Elephant from "../../assets/images/ele.webp";

const ExpertiseData = [
  {
    id: 1,
    imageSrc: "https://images.pexels.com/photos/416405/pexels-photo-416405.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    alt: "Test de alt",
    title: "Délégation d'expertises",
    text: "Trouvez les bons développeurs, architects et Data Scientist Data Analyst afin de construire l’environnement parfait à l’exploitation de la data.",
  },
  {
    id: 2,
    imageSrc: "https://images.pexels.com/photos/356056/pexels-photo-356056.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    alt: "Test de alt",
    title: "Valoriser des données",
    text: "Prenez soin de vos données pour mieux piloter votre activité, personnalisez les expériences et prenez les bonnes décisions.",
  },
  {
    id: 3,
    imageSrc: "https://images.pexels.com/photos/1181354/pexels-photo-1181354.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    alt: "Test de alt",
    title: "Production des données",
    text: "Construisez tout de suite votre environnement de données en mettant en place des KPIs qui permettront d’analyser, mesurer et décider comment piloter votre activité.",
  },
  {
    id: 4,
    imageSrc: "https://images.pexels.com/photos/574071/pexels-photo-574071.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    alt: "Test de alt",
    title: "Agiliser son SI",
    text: "Rendez votre système d’information plus ouvert, plus interopérable, plus scalable et plus réactif pour soutenir vos transformations.",
  },
];

export default ExpertiseData;
