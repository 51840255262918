import React, { useState } from "react";

const TabNavigation = () => {
  const [activeTab, setActiveTab] = useState("CRM");

  const tabs = [
    {
      id: "CRM",
      label: "Gestion des Relations Clients",
      content:
        "Améliorez vos relations avec les clients grâce à Salesforce CRM. Offrez une vue 360 degrés à vos équipes pour une meilleure prise de décision et une approche personnalisée.",
    },
    {
      id: "Marketing",
      label: "Automatisation du Marketing",
      content:
        "Utilisez Salesforce pour automatiser vos efforts de marketing. Ciblez mieux vos campagnes et augmentez votre ROI en intégrant vos données marketing avec vos ventes.",
    },
    {
      id: "Cloud",
      label: "Service Cloud et Support",
      content:
        "Renforcez votre service client avec Salesforce Service Cloud. Offrez un support rapide et efficace pour maintenir la satisfaction et la fidélité de vos clients.",
    },
  ];

  const buttonClass =
    "bg-textColor inline-flex flex-grow items-center justify-center py-2 px-4 text-primaryColor font-bold text-pretty xs:text-sm lg:text-base";

  return (
    <div className="p-4 shadow-xl xs:w-full lg:w-2/3 bg-textColor rounded">
      <ul className="flex pb-2 bg-textColor">
        {tabs.map((tab) => (
          <li
            key={tab.id}
            className="flex-1 text-center text-base bg-textColor"
          >
            <button
              className={`${buttonClass} ${
                activeTab === tab.id ? "active-tab" : ""
              }`}
              onClick={() => setActiveTab(tab.id)}
              style={{ flex: "1 1 0%" }} // Utiliser flex pour assurer que tous les boutons ont la même largeur
            >
              {tab.label}
            </button>
          </li>
        ))}
      </ul>
      <div className="tab-content pt-4 w-full">
        <div className="p-4 text-primaryColor text-base">
          {tabs.find((tab) => tab.id === activeTab)?.content}
        </div>
      </div>
    </div>
  );
};

export default TabNavigation;
