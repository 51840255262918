import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const clients = [
    { id: 1, name: "Accor Arena", images: ["https://cdn.sortiraparis.com/images/80/1665/886415-roger-waters-en-concert-a-l-accor-arena-de-paris-on-y-etait-on-vous-raconte.jpg", "https://static.wixstatic.com/media/a982c0_4ee680a89de946b4a6abeba22f7d983c~mv2.jpg/v1/fill/w_640,h_426,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/a982c0_4ee680a89de946b4a6abeba22f7d983c~mv2.jpg", "https://cdn.sortiraparis.com/images/80/97166/724838-champion-des-pistes-des-animations-sportives-gratuites-autour-des-jo-a-tester-a-l-accor-arena.jpg", "https://www.europe2.fr/wp-content/uploads/europeradio/2022/03/media-5753.jpg"] },
    { id: 2, name: "FC Nantes", images: ["https://images.mnstatic.com/bf/42/bf42425e22f29f58a430ceb9aef2e957.jpg", "https://i0.wp.com/au-stade.fr/wp-content/uploads/2019/04/Nantes-Lille.jpg?ssl=1", "https://www.fcnantes.com/img/2122/photodegroupe280921/26.jpg", "https://i.eurosport.com/2018/03/29/2303188-47962550-2560-1440.jpg"] },
    { id: 3, name: "Stade Rochelais", images: ["https://media.sudouest.fr/19454956/1000x500/20231119230516-1911223xl24135.jpg?v=1713971742", "https://static.pacifa3d.com/StadeMarcelDeflandreLAROCHELLE/WIMS/STADE-ROCHELAIS_RUGBY_v2019-2020/default/Top.jpg", "https://static.actu.fr/uploads/2021/07/icon-mm1-8642.jpg", "https://media.licdn.com/dms/image/C4E1BAQG23GJNi9h5ig/company-background_10000/0/1583926963584?e=2147483647&v=beta&t=SduF7jqj-oI1Q5Q4TC5HvttofSSZbPavJcvZM091ftY"] },
    { id: 4, name: "Girondins de Bordeaux", images: ["https://www.francebleu.fr/s3/cruiser-production/2015/09/ffd047b1-520d-11e5-add9-005056a87fa3/1200x680_projet-matmutphilippe-caumes.jpg", "https://media.ouest-france.fr/v1/pictures/MjAyMTA4M2NhNzg0MGI4ZWJlZTM5MzU1YTBiM2NmYWQyMGJiYjY?width=1260&height=708&focuspoint=61%2C22&cropresize=1&client_id=bpeditorial&sign=f21eb937409630b6bf802d324c244e6a34ceefb49e25f7fab5cde405f6609084", "https://media.sudouest.fr/2854775/1000x500/so-5c36ae0c66a4bda9436a4920-ph0.jpg?v=1547110274", "https://media.sudouest.fr/6649915/1000x500/so-5cf51a8366a4bd0a095ac1f7-ph0.jpg?v=1634742779"] },
    { id: 5, name: "Adidas Arena", images: ["https://cdn.sortiraparis.com/images/80/100773/1014392-visuels-salles-de-spectacle-et-theatres-adidas-arena.jpg", "https://www.francetvinfo.fr/pictures/7ZB0QAhWB7G_-Oo8-6AECgoFzE8/1500x843/2024/02/11/000-34j82km-65c931a76e9b1785479849.jpg", "https://i0.wp.com/sportetsociete.org/wp-content/uploads/2023/12/GB3X5SrWQAAzeH-.jpg?fit=3648%2C2736&ssl=1", "https://thegoodlife.fr/wp-content/thumbnails/uploads/sites/2/2024/02/adidas-arena-florence-pernet-tt-width-2000-height-1282-fill-0-crop-0-bgcolor-eeeeee.jpg"] },
];

export function ScrollableTabsButtonPrevent() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ flexGrow: 1, bgcolor: 'var(--textColor)', display: 'flex', height: '100%', flexDirection: { xs: 'column', md: 'row' } }}>
            <Tabs
                orientation={{ xs: 'horizontal', md: 'vertical' }}
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                sx={{
                    alignItems: 'center',
                    margin: "1rem",
                    '.MuiTabs-flexContainer': {
                        flexDirection: { md: 'column' },
                        justifyContent: 'center',
                        alignItems: 'center'
                    },
                    '.MuiTabs-scrollButtons': {
                        '& .MuiSvgIcon-root': {
                            fontSize: '2rem',
                        }
                    },
                    '.Mui-selected': {  // Ajout de style pour l'onglet sélectionné
                        color: 'var(--primaryColor)',  // Couleur du texte pour l'onglet actif
                        borderBottom: '2px solid var(--primaryColor)',  // Bordure soulignée pour l'onglet actif
                    },
                    color: 'var(--primaryColor)',
                }}
            >
                {clients.map((client, index) => (
                    <Tab
                        key={index}
                        label={client.name}
                        {...a11yProps(index)}
                        sx={{
                            color: 'var(--primaryColor)',
                            fontSize: '1rem',
                            minWidth: { xs: '1rem', sm: 'auto' },
                            '&.Mui-selected': {  // S'assurer que l'onglet actif est visible
                                color: 'var(--primaryColor)',  // Changement de couleur pour l'onglet actif
                                borderBottom: '2px solid var(--primaryColor)',  // Bordure soulignée plus visible
                            }
                        }}
                    />
                ))}
            </Tabs>
            {clients.map((client, index) => (
                <TabPanel key={index} value={value} index={index}>
                    <div className='flex flex-wrap gap-2 h-auto w-full xs:justify-center lg:justify-start lg:items-center'>
                        {client.images.map((image, i) => (
                            <img key={i} className='max-h-[20rem] xs:w-full lg:w-auto object-cover' src={image} alt={`Image ${i + 1}`} />
                        ))}
                    </div>
                </TabPanel>
            ))}
        </Box>
    );
}