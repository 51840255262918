// Header.jsx
import React from "react";
import { Link } from "react-router-dom";
import HeaderImage from "../../assets/images/header-planète.webp";

function Header() {
  return (
    <section className="flex flex-wrap bg-stadium bg-no-repeat bg-cover bg-bottom">
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-2 w-full z-30">
        {/* Text Content */}
        <div className="flex flex-col w-full xs:items-center md:items-end">
          <h1 className="text-center md:text-right text-textColor font-extrabold xs:text-lg lg:text-2xl uppercase px-5 mt-10 md:mt-15 lg:mt-20 xl:mt-36 2xl:mt-44">
            L'ESN socialement responsable
          </h1>
          <p className="text-center md:text-right text-textColor xs:text-base lg:text-md text-balance ">
            L’entreprise de services du numérique (ESN) où l’humain et
            l’environnement sont au coeur de notre raison d’être.
          </p>

          <div className="flex justify-center p-3 flex-col">
            <Link
              to="notre-histoire"
              className="inline-flex text-base text-center items-center px-3 py-2 font-medium shadow-xl text-primaryColor bg-textColor hover:bg-primaryColor hover:text-textColor mt-5 mr-1 border hover:border hover:border-textColor rounded-md cursor-pointer mx-auto"
            >
              En savoir plus
              <svg
                className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </Link>
          </div>
        </div>
        {/* Image */}
        <div className="w-full md:w-auto flex justify-center items-start">
          <img
            className="xs:h-2/3 lg:h-5/6 text-center w-auto mt-3"
            src={HeaderImage}
            alt="Globe illustratif représentant un engagement envers la durabilité avec une végétation luxuriante et des océans propres, symbolisant les valeurs écologiques de Tech' Talent. "
          />
        </div>
      </div>
    </section>
  );
}

export default Header;
