const RegieData = [
  {
    id: 1,
    imageSrc:
      "https://images.unsplash.com/photo-1608303588026-884930af2559?q=80&w=2006&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    alt: "Professionnels en réunion de travail",
    title: "Des intervenants professionnels",
    text: "Trouvez les bons développeurs, architects et Data Scientist pour exploiter la data. Nos experts vous guident à chaque étape.",
  },
  {
    id: 2,
    imageSrc:
      "https://images.unsplash.com/photo-1527066579998-dbbae57f45ce?q=80&w=1970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    alt: "Détails d'un serveur informatique",
    title: "Normes RGPD respectées",
    text: "Nous respectons les normes RGPD pour protéger vos données. Notre équipe offre des solutions innovantes pour tous défis informatiques.",
  },
  {
    id: 3,
    imageSrc:
      "https://images.unsplash.com/photo-1600880292089-90a7e086ee0c?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    alt: "Salle de contrôle informatique",
    title: "Un suivi continu",
    text: "Nous assurons un suivi continu pour maintenir vos systèmes performants. Notre équipe est dédiée à fournir des solutions innovantes.",
  },
  {
    id: 4,
    imageSrc:
      "https://images.pexels.com/photos/3760529/pexels-photo-3760529.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    alt: "Équipe de développeurs discutant d'un projet",
    title: "Nos réponses",
    text: "Nous offrons des solutions personnalisées pour chaque projet, avec des experts en développement, cybersécurité et support technique.",
  },
];

export default RegieData;
