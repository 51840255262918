import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "../Cards/cards.css";
import GiphyVideo from "../../../assets/videos/vid-giphy.webm";
import ExpertiseVideo from "../../../assets/videos/vid-informatique.webm";
import RegieVideo from "../../../assets/videos/vid-regie.webm";
import DropboxVideo from "../../../assets/videos/vid-dropbox.webm";

const cardData = [
  {
    href: "/expertise-salesforce",
    videoSrc: GiphyVideo,
    headline: "Salesforce",
    text: "Une équipe d'experts Salesforce passionnée est là pour vous assister dans vos projets d'intégration avec une grande facilité",
  },
  {
    href: "/expertise-it",
    videoSrc: ExpertiseVideo,
    headline: "Expertise IT",
    text: "De la stratégie digitale à la réalisation de projets numériques, en passant par le e-commerce et le marketing",
  },
  {
    href: "/regie",
    videoSrc: RegieVideo,
    headline: "Régie",
    text: "Nous nous spécialisons dans la mise en œuvre de solutions sur mesure qui répondent aux besoins spécifiques de nos clients",
  },
  {
    href: "/dropbox",
    videoSrc: DropboxVideo,
    headline: "Intégrateur Dropbox",
    text: "Notre équipe d'experts est à votre disposition pour assurer l'intégration de Dropbox adaptée aux besoins spécifiques de votre organisation",
  },
];

function Cards() {
  const cardRefs = useRef([]);

  useEffect(() => {
    const height = (elem) => elem.getBoundingClientRect().height;
    const distance = (elemA, elemB, prop) =>
      elemB.getBoundingClientRect()[prop] - elemA.getBoundingClientRect()[prop];
    const factor = (elemA, elemB, prop) =>
      elemB.getBoundingClientRect()[prop] / elemA.getBoundingClientRect()[prop];

    cardRefs.current.forEach((elem) => {
      const head = elem.querySelector(".card__head");
      const image = elem.querySelector(".card__image");
      const author = elem.querySelector(".card__author");
      const body = elem.querySelector(".card__body");
      const foot = elem.querySelector(".card__foot");

      elem.onmouseenter = () => {
        elem.classList.add("hover");

        const imageScale = 0.6 + factor(head, body, "height");
        image && (image.style.transform = `scale(${imageScale})`);

        const bodyDistance = height(foot) * -0.8;
        body && (body.style.transform = `translateY(${bodyDistance}px)`);

        const authorDistance = distance(head, author, "height");
        author && (author.style.transform = `translateY(${authorDistance}px)`);
      };

      elem.onmouseleave = () => {
        elem.classList.remove("hover");
        image && (image.style.transform = "none");
        body && (body.style.transform = "none");
        author && (author.style.transform = "none");
      };
    });
  }, []);

  return (
    <div className="flex flex-wrap gap-6 w-auto mx-4 xs:justify-center xl:justify-start">
      {cardData.map((card, index) => (
        <Link
          key={index}
          to={card.href}
          className="card relative flex flex-col xs:w-auto max-w-[26rem] max-h-[40rem] no-underline overflow-hidden transition-colors bg-backgroundColor after:transition-opacity delay-150 duration-150 ease-in-out hover:delay-0 shadow-lg rounded-lg text-md font-thin"
          ref={(el) => (cardRefs.current[index] = el)}
        >
          <div className="card__head relative pt-[60%] bg-backgroundColor">
            <div className="card__image pointer-events-none flex justify-center items-center bg-backgroundColor absolute top-0 left-0 right-0 bottom-0 bg-cover bg-center origin-top-center transition-transform delay-150 p-4 hover:scale-150 after:contents after:absolute after:opacity-50 after:from-black after:delay-150 after:transition-opacity">
              <video
                autoPlay
                loop
                muted
                playsInline // Cet attribut est important pour la lecture automatique sur iOS
                className="h-full w-full object-cover pointer-events-none"
              >
                <source src={card.videoSrc} type="video/webm" />
                Votre navigateur ne prend pas en charge la vidéo.
              </video>
            </div>
            <div className="card__author bg-backgroundColor"></div>
          </div>
          <div className="card__body relative p-4 transition-transform delay-150 bg-backgroundColor">
            <h2 className="card__headline font-bold pb-2 text-primaryColor xs:text-md lg:text-lg">
              {card.headline}
            </h2>
            <p className="card__text text-primaryColor font-light xs:text-sm lg:text-base">
              {card.text}
            </p>
          </div>
          <div className="card__foot absolute left-0 right-0 bottom-0 py-4 px-4 opacity-0 transition-opacity bg-backgroundColor">
            <Link
              to={card.href}
              className="card__link inline-flex text-center items-center px-3 py-2 font-medium shadow-xl text-primaryColor bg-textColor hover:bg-primaryColor hover:text-textColor focus:ring focus:outline-none focus:ring-secondaryColor hover:border hover:border-textColor rounded-md cursor-pointer xs:text-sm lg:text-base"
            >
              En savoir plus
              <svg
                className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </Link>
          </div>
          <div className="card__border absolute left-0 bottom-0 w-full h-2 scale-y-0 transition-transform"></div>
        </Link>
      ))}
    </div>
  );
}

export default Cards;
