import Edna from "./assets/images/Team/edna.webp";
import Patricia from "./assets/images/Team/patricia.webp";
import PaulM from "./assets/images/Team/paulm.webp";
import PaulB from "./assets/images/Team/paulb.webp";
import Anna from "./assets/images/Team/anna.webp";
import Romain from "./assets/images/Team/romain.webp";
import Sebastien from "./assets/images/Team/sebastien.webp";
import Etienne from "./assets/images/Team/etienne.webp";
import Anaïs from "./assets/images/Team/anais.webp";
import Antoine from "./assets/images/Team/antoine.webp";
import Louise from "./assets/images/Team/louise.webp";
import Sacha from "./assets/images/Team/sacha.webp";
import Christophe from "./assets/images/Team/christophe.webp";
import Pierre from "./assets/images/Team/pierre.webp";

const galleryData = [
  {
    id: 2,
    imageUrl: Etienne,
    name: "Etienne",
    title: "Tech Lead Salesforce",
    linkedinUrl: "https://www.linkedin.com/in/etiennegaudry/",
    categories: ["developpement", "gestion projet"],
    alt: "Portrait de Etienne, Tech Lead Salesforce au sein de Tech'Talents",
  },
  {
    id: 3,
    imageUrl: Anaïs,
    name: "Anaïs",
    title: "CEO",
    linkedinUrl:
      "https://www.linkedin.com/in/ana%C3%AFs-helie-beugin-4186831b/",
    categories: ["direction"],
    alt: "Portrait de Anaïs, CEO au sein de Tech'Talents",
  },

  {
    id: 5,
    imageUrl: PaulB,
    name: "Paul",
    title: "Développeur Salesforce",
    linkedinUrl: "https://www.linkedin.com/in/paulbera/",
    categories: ["developpement"],
    alt: "Portrait de Paul, Développeur Salesforce au sein de Tech'Talents",
  },
  {
    id: 6,
    imageUrl: Sacha,
    name: "Sacha",
    title: "Développeur Salesforce",
    linkedinUrl: "https://www.linkedin.com/in/sacha-don-561a981b9/",
    categories: ["developpement"],
    alt: "Portrait de Sacha, Développeur Salesforce au sein de Tech'Talents",
  },
  {
    id: 7,
    imageUrl: Louise,
    name: "Louise",
    title: "Développeuse Salesforce",
    linkedinUrl: "https://www.linkedin.com/in/louise-delbecque-084319183/",
    categories: ["developpement"],
    alt: "Portrait de Louise, Développeuse Salesforce au sein de Tech'Talents",
  },

  // Prochaine personne id 8

  {
    id: 9,
    imageUrl: Christophe,
    name: "Christophe",
    title: "Développeur FullStack",
    linkedinUrl: "https://www.linkedin.com/in/christophe-manidren-8b962119/",
    categories: ["developpement"],
    alt: "Portrait de Christophe, Développeur FullStack au sein de Tech'Talents",
  },
  {
    id: 10,
    imageUrl: Anna,
    name: "Anna",
    title: "Développeuse Salesforce",
    linkedinUrl: "https://www.linkedin.com/in/anna-sargsyan-80941868/",
    categories: ["developpement"],
    alt: "Portrait de Anna, Développeuse Salesforce au sein de Tech'Talents",
  },
  {
    id: 11,
    imageUrl: Sebastien,
    name: "Sébastien",
    title: "Développeur Salesforce",
    linkedinUrl:
      "https://www.linkedin.com/in/s%C3%A9bastien-legrand-0b9736267/",
    categories: ["developpement"],
    alt: "Portrait de Sébastien, Développeur Salesforce au sein de Tech'Talents",
  },
  {
    id: 12,
    imageUrl: Antoine,
    name: "Antoine",
    title: "Développeur Salesforce",
    linkedinUrl:
      "https://www.linkedin.com/in/antoine-griffit-%F0%9F%A7%91%F0%9F%8F%BD%E2%80%8D%F0%9F%92%BB%F0%9F%8C%B4-557080198/",
    categories: ["developpement"],
    alt: "Portrait de Antoine, Développeur Salesforce au sein de Tech'Talents",
  },
  {
    id: 13,
    imageUrl: Romain,
    name: "Romain",
    title: "Développeur Salesforce",
    linkedinUrl: "https://www.linkedin.com/in/romain-trochu-0a7385263/",
    categories: ["developpement"],
    alt: "Portrait de Romain, Développeur Salesforce au sein de Tech'Talents",
  },
  {
    id: 14,
    imageUrl: Pierre,
    name: "Pierre",
    title: "Chef de projets",
    linkedinUrl: "https://www.linkedin.com/in/pierre-vandevelde-16454722a/",
    categories: ["gestion projet", "developpement"],
    alt: "Portrait de Pierre, Chef de projet et développeur au sein de Tech'Talents",
  },
];

export default galleryData;
