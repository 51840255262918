import React from "react";
import { Helmet } from "react-helmet";
import SalesforceCards from "../components/Salesforce/SalesforceCards";
import SalesforceData from "../components/Salesforce/SalesforceData";
import SalesforceImg from "../assets/images/Salesforce1.webp";
import SalesforceTab from "../components/Salesforce/SalesforceTabs";
import { Link } from "react-router-dom";


function Salesforce() {
  return (
    <div>
      <Helmet>
        <title>Contactez Tech Talents - Nous sommes là pour vous aider</title>
        <meta
          name="description"
          content="Vous avez des questions ou besoin d'une expertise ? Contactez Tech Talents dès aujourd'hui pour discuter de vos projets et découvrir comment nous pouvons vous aider."
        />
      </Helmet>
      <div className="flex xs:h-[15rem] lg:h-[30rem] items-center justify-center bg-salesforce bg-center bg-cover">
        <div className="w-full flex flex-col items-center justify-center gap-5 bg-black/10 h-[30rem]">
          <h1 className="xs:text-lg lg:text-2xl text-center p-2 text-white font-extrabold uppercase">
            Notre Expertise
          </h1>
          <Link
            to="https://www.salesforce.com/fr/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="hover:scale-125 duration-300 hover:grayscale xs:h-24 lg:h-28 w-auto"
              src="https://c1.sfdcstatic.com/content/dam/sfdc-docs/www/logos/logo-salesforce.svg"
              alt="Salesforce Logo"
            />
          </Link>
        </div>
      </div>
      {/* Cards */}
      <div className="grid xs:grid-cols-1 lg:grid-cols-2 gap-12 mx-4">
        {SalesforceData.map((card) => (
          <SalesforceCards key={card.id} card={card} />
        ))}
      </div>

      <div className="grid lg:grid-cols-2 xs:grid-cols-1 p-6 mt-10 gap-12">
        <div className="flex justify-center">
          <img
            src={SalesforceImg}
            alt="Diagramme de services Salesforce montrant diverses fonctionnalités comme le marketing, les ventes, le service, le commerce et la philanthropie, reflétant les solutions intégrées offertes par Tech Talents"
          />
        </div>
        <div className="flex justify-center">
          <iframe
            title="Vidéo Salesforce présentée par Etienne Gaudry, Tech Lead chez Tech'Talents"
            width="800"
            height="400"
            src="https://www.youtube-nocookie.com/embed/FkxBojnUKxs?si=rwVlwa0jqiqq8fZ9"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <div className="flex justify-center mx-auto my-10">
        <SalesforceTab />
      </div>
    </div>
  );
}

export default Salesforce;
