import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

function Dropbox() {
  const sectionsData = [
    {
      id: 1,
      title: "Accessibilité",
      points: [
        "Accès universel : Accédez à vos fichiers depuis n'importe quel appareil connecté à Internet",
        "Multi-plateforme : Compatible avec Windows, macOS, Linux, iOS et Android",
        "Pas de restrictions géographiques : Accédez à vos données de n'importe où dans le monde",
      ],
      imageUrl:
        "https://aem.dropbox.com/cms/content/dam/dropbox/dmep/assets/past-events-images/new-it-shape-future.jpg/_jcr_content/renditions/new-it-shape-future.webp",
    },
    {
      id: 2,
      title: "Synchronisation en temps réel",
      points: [
        "Mise à jour instantanée : Les modifications apportées à un fichier sont immédiatement synchronisées sur tous les appareils",
        "Collaboration efficace : Permet à plusieurs utilisateurs de travailler sur le même document simultanément",
        "Sécurité des données : Garantit que vous travaillez toujours sur la version la plus récente d'un document",
      ],
      imageUrl:
        "https://images.pexels.com/photos/3184451/pexels-photo-3184451.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    {
      id: 3,
      title: "Partage facile",
      points: [
        "Partage de liens : Partagez facilement des fichiers et des dossiers en envoyant un lien",
        "Contrôle d'accès : Déterminez qui peut voir ou modifier chaque fichier",
        "Simplification des échanges : Évite les pièces jointes volumineuses par e-mail, optimisant la communication",
      ],
      imageUrl:
        "https://images.pexels.com/photos/1181724/pexels-photo-1181724.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    {
      id: 4,
      title: "Sécurité",
      points: [
        "Chiffrement : Chiffrement des données en transit et au repos pour une protection optimale",
        "Authentification à deux facteurs : Ajoute une couche de sécurité supplémentaire pour l'accès au compte",
        "Récupération de compte : Options pour récupérer l'accès en cas de perte de mot de passe ou de compromission de compte",
      ],
      imageUrl:
        "https://images.pexels.com/photos/60504/security-protection-anti-virus-software-60504.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    {
      id: 5,
      title: "Intégration avec d'autres applications",
      points: [
        "Compatibilité étendue : Fonctionne avec des outils comme Microsoft Office, Slack, et Zoom",
        "Automatisation du flux de travail : Simplifie les processus en intégrant des actions Dropbox dans d'autres applications",
        "Écosystème riche : Permet de centraliser le travail en connectant divers services et outils",
      ],
      imageUrl:
        "https://images.pexels.com/photos/1181675/pexels-photo-1181675.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    {
      id: 6,
      title: "Récupération de fichiers et historique des versions",
      points: [
        "Protection contre la perte de données : Récupérez des fichiers supprimés ou revenez à des versions antérieures",
        "Historique étendu : Accès à l'historique des versions pour une période déterminée, selon le plan choisi",
        "Gestion des erreurs : Permet de corriger rapidement les erreurs en restaurant des versions précédentes",
      ],
      imageUrl:
        "https://images.pexels.com/photos/17489153/pexels-photo-17489153/free-photo-of-bleu-industrie-internet-connexion.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
  ];

  return (
    <div className="w-full">
      <Helmet>
        <title>
          Partenariat Dropbox par Tech Talents - Simplifiez votre stockage cloud
        </title>
        <meta
          name="description"
          content="Découvrez comment le partenariat de Tech Talents avec Dropbox peut révolutionner votre manière de stocker, partager et collaborer sur vos fichiers dans le cloud."
        />
      </Helmet>
      <div className="grid xs:grid-cols-1 lg:grid-cols-2 bg-textColor p-10 items-center">
        <div className="flex flex-col items-center justify-center p-8 order-2 lg:order-1 gap-4">
          <svg
            class="xs:h-24 lg:h-44 text-primaryColor border-2 border-primaryColor rounded-full p-4"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M12 6.2 7 9.4l5 3.2-5 3.2-5-3.3 5-3.1-5-3.2L7 3l5 3.2ZM7 17.8l5-3.2 5 3.2-5 3.2-5-3.2Z" />
            <path d="m12 12.5 5-3.1-5-3.2L17 3l5 3.2-5 3.2 5 3.2-5 3.2-5-3.3Z" />
          </svg>

          <h1 className="xs:text-md lg:text-xl text-primaryColor font-bold uppercase text-center box-shadow">
            Tech Talents votre intégrateur Dropbox
          </h1>
          <Link
            className="text-textColor bg-primaryColor hover:bg-textColor hover:text-primaryColor border hover:border-primaryColor px-3 py-2 mt-4 uppercase text-center mx-auto shadow-xl"
            to="https://www.dropbox.com/"
            target="blank"
          >
            {" "}
            Plus de renseignements{" "}
          </Link>
        </div>
        <div className="flex flex-col items-center justify-center px-8 order-1 lg:order-2 ">
          <img
            className="h-auto max-w-full rounded-lg shadow-lg"
            src="https://miro.medium.com/v2/resize:fit:720/format:webp/1*TL8POwNUk4MyyTNZRnqZ_g.jpeg"
          />
        </div>
      </div>
      <h2 className="xs:text-lg lg:text-xl font-extrabold xs:p-4 xs:text-center lg:p-8 text-textColor uppercase text-left mt-8">
        Les avantages de Dropbox
      </h2>
      {sectionsData.map((section, index) => (
        <div
          key={section.id}
          className={`flex flex-col lg:flex-row mx-4 my-8 ${index % 2 !== 0 ? "lg:flex-row-reverse" : ""
            }`}
        >
          {/* Section Texte */}
          <div className="flex flex-col lg:w-1/2 p-4 justify-center">
            <p className="stroke-font text-primaryColor text-lg lg:text-lg uppercase underline-offset-8 underline mb-6">
              <span className="text-lg lg:text-xl">{section.id}.</span>{" "}
              {section.title}
            </p>
            <div className="text-textColor text-base">
              {section.points.map((point, pointIndex) => (
                <div key={pointIndex} className="mb-4">
                  <span className="h-3 w-3 bg-textColor inline-block mr-2"></span>
                  <p className="inline xs:text-base lg:text-md">{point}</p>
                </div>
              ))}
            </div>
          </div>

          {/* Section Image */}
          <div className="lg:w-1/2 p-4 flex justify-center items-center ">
            <img
              className="max-h-[500px] w-auto max-w-full rounded-lg shadow-xl"
              src={section.imageUrl}
              alt={`Illustration pour ${section.title}`}
            />
          </div>
        </div>
      ))}
    </div>
  );
}

export default Dropbox;
