import React from "react";
import { Link } from "react-router-dom";

function JobCard({ location, imageSrc, altText, title, description, link }) {
  return (
    <div className="max-w-[400px] bg-backgroundColor shadow-xl rounded-md m-10">
      <div className="flex flex-col max-w-[400px] bg-backgroud relative">
        <h3 className="absolute top-0 left-0 z-10 text-primaryColor p-3 bg-textColor rounded-md m-2 font-bold">
          {location}
        </h3>
        <img className="h-[14rem] w-auto p-3" src={imageSrc} alt={altText} />
      </div>
      <div className="flex flex-col text-left p-4">
        <h1 className="font-bold mb-3 text-primaryColor text-base">{title}</h1>
        <p className="text-primaryColor">{description}</p>
      </div>
      <div className="flex flex-wrap p-3 ">
        <Link to={link}>
          <a
            target="blank"
            className="inline-flex items-center px-3 py-2 text-sm font-medium text-center shadow-xl text-textColor bg-primaryColor rounded-lg hover:bg-textColor hover:text-primaryColor focus:ring-4 focus:outline-none focus:ring-secondaryColor "
          >
            Je postule
            <svg
              className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          </a>
        </Link>
      </div>
    </div>
  );
}

export default JobCard;
